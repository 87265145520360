.secao-capa-sobre {
  display: flex;
  background-image: url('/src/assets/treinamento.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
}

.secao-capa-sobre div {
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.paragrafos p {
  margin-bottom: 30px;
}

.quem-somos {
  padding: 80px 20px;
}

.paragrafos {
  text-align: justify;
}