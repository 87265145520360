* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

.limita-largura {
  max-width: 1024px;
  margin: 0 auto;
}

main {
  animation: fadeIn 2s linear;
}

.impar {
  background-color: darkgray;
}

@keyframes fadeIn {
  from{opacity: 0;}
  to{opacity: 1;}
}
