header {
  width: 100%;
  /* background-image: linear-gradient(to left, #0770AD, #214961, #04446A); */
  background-image: linear-gradient(to left, #060d4c, #18183d, #06446b);
  background-size: 130% 100%;
  border-bottom: 1px solid rgb(217, 211, 211);
  padding: 10px;
  z-index: 1;
}

.conteudo-topo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.conteudo-topo img {
  width: 200px;
  /* height: 80px; */
}

.link-navegacao {
  margin: 0 12px;
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
  font-size: 17px;
  position: relative;
}

.link-navegacao::after {
  content: " ";
  width: 0%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.5s ease-in-out;
}

.link-navegacao:hover::after {
  width: 100%;
}

.mobile-menu-icon {
  display: none;
}

.mobile-menu {
  display: none;
}

.logo-mobile {
  display: none;
}


@media screen and (max-width: 768px) {
  .conteudo-topo img {
    width: 100px;
  }

  .mobile-menu-icon img {
    width: 36px;
  }

  nav {
    border-top: 1px solid rgb(217, 211, 211);
    padding: 10px;
  }

  .mobile-menu nav {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 1rem;
  }

  .mobile-menu .link-navegacao {
    display: block;
    padding-top: 1.2rem;
  }

  .menu-desktop {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
  }

  .mobile-menu-icon button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .open {
    display: block;
  }
  
}