footer {
  width: 100%;
  background-image: linear-gradient(to left, #060d4c, #18183d, #06446b);
  background-size: 130% 100%;
}

.rodape-site {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px 20px;
}


.endereco,
.contato,
.horarios {
  color: #ffffff;
  width: 200px;
}

.direito-autoral {
  color: #ffffff;
  text-align: center;
  padding: 20px;
}

.redes-sociais {
  display: flex;
  justify-content: center;
}

.redes-sociais img {
  width: 36px;
  margin: 0 10px;
}



@media screen and (max-width: 768px) {
  .rodape-site {
    align-items: center;
    flex-direction: column;
    padding: 80px 0;
  }

  .rodape-site div {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
}