.secao-capa {
  display: flex;
  background-image: url('/src/assets/capa_protecao.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vh;
}

.secao-capa div {
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ecommerce {
  text-decoration: none;
}

.faca-parte {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  text-align: justify;
}

.faca-parte p {
  margin-left: 15px;
  color: #1c6831  ;
}

.faca-parte img {
  width: 50px;
}

.secao-endereco {
  padding: 80px 20px;
}

.subtitulo {
  font-size: 28px;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: #214961;
  font-weight: 600;
}

.diferenca {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.secao-diferenciais {
  padding: 80px 20px;
  background-color: #f1efed;
}

.secao-parceiros {
  padding: 80px 20px;
  background-color: #f1efed;
}

.secao-missao {
  padding: 100px 20px;
}

.todos-parceiros img {
  width: 320px;
  padding: 30px;
}

.todos-parceiros {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

}

.titulo-objetivo {
  font-size: 70px;
}

.objetivo {
  display: flex;
  align-items: center;
  color: #214961;
  padding: 15px;
  margin-bottom: 15px;
}

.objetivos {
  display: flex;
  align-items: space-around;
}

.objetivo p {
  font-size: 0.8rem;
  text-align: justify;
  margin-left: 20px;
}

.diferenca img {
  width: 40px;
}

/* .img-destaque {
  width: 192px;
  height: 192px;
} */

.diferenca p {
  margin-left: 20px;
  text-transform: uppercase;
  font-weight: 600;
}

.diferenciais-01 {
  display: flex;
  flex-direction: column;
}

.cards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.card {
  width: 30%;
  border: 1px solid rgb(216, 210, 210);
  border-radius: 12px;
  box-shadow: 2px 2px 15px 2px #d9dde1;
  padding: 40px;
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  transition: 1s;
}

.card:hover {
  transform: scale(1.05);
  /* transition: 0.5s ease-in-out; */
}

.card img {
  width: 80px;
  margin-bottom: 15px;
}

.secao-endereco iframe {
  border: none;
}

.card h3 {
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #214961;
}

.card p {
  font-size: 14.1px;
  color: #214961;
  margin-bottom: 15px;
}


.titulo {
  line-height: 72px;
}

.titulo span {
  color: white;
  text-decoration: none;
}

.titulo h1 {
  color: rgb(177, 188, 177);
  font-size: 56px;
}

.titulo p {
  color: whitesmoke;
  font-size: 36px;
}

.secao-capa span {
  text-decoration-color: rgb(10, 179, 10);
  text-decoration: underline;
}

@media screen and (max-width: 500px) {
  .secao-capa {
    background-position: right;
  }

  .diferenciais-01 {
    flex-direction: column;
  }

  .cards {
    flex-direction: column;
  }

  .card {
    width: 100%;
  }

  .objetivos {
    flex-direction: column;
  }

  .subtitulo {
    font-size: 18px;
  }

  .titulo h1 {
    font-size: 48px;
  }

  .titulo {
    line-height: 40px;
  }
  
  .titulo p {
    font-size: 22px;
  }

  .todos-parceiros img {
    width: 50%;
  }

  .secao-endereco iframe {
    width: 100%;
  }
}

