.secao-capa-produtos {
  display: flex;
  background-image: url('/src/assets/contato.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
}

.secao-capa-produtos div {
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.titulo-produtos {
  line-height: 72px;
}

.telefone {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.pessoa {
  margin-bottom: 40px;
}

.telefone p {
  color: #1c6831;
  font-weight: 600;
}

.telefone img {
  width: 100px;
}

.titulo-produtos h1 {
  color: aliceblue;
  font-size: 70px;
  text-transform: uppercase;
}

.secao-produtos {
  padding: 80px 20px;
}

.btn{
  padding: 8px;
  background-image: linear-gradient(to left, #04446A,#214961,#0770AD);
  background-size: 400% 100%;
  animation: btn-animado 1.5s infinite alternate;
  border: none;
  color: #fff;
  border-radius: 8px;
}

.btn:hover {
  cursor: pointer;
  animation: anima 0.5s ease-in-out infinite alternate;
}

@keyframes btn-animado {
  0% {background-position-x: 0%;}
  100%{background-position-x: 100%;}
}

@keyframes anima {
  0%{transform: scale(1);}
  100%{transform: scale(1.05);}
}

@media screen and (max-width: 425px) {
  .secao-capa-produtos {
    background-position: right;
  }

  .titulo-produtos h1 {
    font-size: 48px;
  }
}